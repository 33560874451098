import React, { useState } from "react"
import { graphql } from "gatsby"
import { Flex, Button, Container } from "@chakra-ui/react"

import Seo from "../components/seo"

import Layout from "../components/Layout"
import Categories from "../components/Home/Categories"
import PostsContainer from "../components/Home/PostCards"
import MoreQuestions from "../components/Home/MoreQuestions"

import GetWeld from "../components/Common/GetWeld"

const IndexPage = ({ data }) => {
  const { allPosts } = data

  const formattedPost = [...allPosts.edges.map(post => post.node)]

  const categories = formattedPost
    .filter(post => post.frontmatter.categories !== null)
    .reduce(
      (acc, cur) => [...new Set([...acc, ...cur.frontmatter.categories])],
      []
    )

  const [endIndex, setEndIndex] = useState(10)

  const slicedPost = formattedPost.slice(0, endIndex)

  const canLoadMore = endIndex < formattedPost.length

  const handleLoadMore = () => {
    setEndIndex(endIndex+10)
  }

  return (
    <Layout>
      <Seo title="Home" />
      <Container maxW={"container.xl"}>
        <Categories categories={categories} />
        <PostsContainer posts={slicedPost} showDownload />
        {canLoadMore && (
          <Flex
            justifyContent="center"
            width="full"
            height="300px"
            background="rgb(255, 255, 255,0.2)"
            alignItems="center"
            mt="-100px"
          >
            <Button
              variant="outline"
              colorScheme="green"
              to="/"
              fontWeight="medium"
              fontSize="14px"
              color="black"
              bg="transparent"
              border="1px solid"
              _hover={{}}
              px="32px"
              py="19px"
              h="60px"
              onClick={() => {
                handleLoadMore()
              }}
              borderRadius="3px"
            >
              Load more blog posts
            </Button>
          </Flex>
        )}
        <GetWeld />
      </Container>
      <MoreQuestions />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    allPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { ne: false } } }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            description
            categories
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 362
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          timeToRead
        }
      }
    }
  }
`
