import * as React from "react"
import { Box } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from './footer'

import "./global.css"

const Layout = ({ children,title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryPage {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Box bg="#F7F7F4">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      {children}
      <Footer/>
    </Box>
  )
}

export default Layout
