import React from "react"
import { Flex, Heading, HStack, Image, Link, Divider } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"

const Categories = ({categories}) => {
  return (
    <Flex direction="column">
      <Flex justifyContent="center" position="relative" margin="auto">
        <Heading fontSize={{ base: "30px", md: "64px" }}>Blog posts</Heading>
        <Image src="/images/star_one.svg" position="absolute" left="-53px" />
        <Image src="/images/star_two.svg" position="absolute" right="-48px" />
      </Flex>

      <HStack
        spacing={{ base: "14px", md: "45px" }}
        justifyContent={{ base: "flex-start", md: "center" }}
        mt="30px"
        overflowX="scroll"
        shouldWrapChildren
        pb="2"
      >
        <>
        <Link
          as={GatsbyLink}
          to="/"
          activeClassName="category_link_active"
          fontSize={{ base: "14px", md: "20px" }}
        >
          All categories
        </Link>
        <Divider
          orientation="vertical"
          h="21px"
          ml="44px"
          display={{ base: "none", md: "initial" }}
        />
        </>
        {categories.map((category, index) => {
          return (
            <>
              <Link
                as={GatsbyLink}
                to={`/category/${category.toLowerCase()}`}
                activeClassName="category_link_active"
                fontSize={{ base: "14px", md: "20px" }}
              >
                {category}
              </Link>
              {index + 1 !== categories.length && (
                <Divider
                  orientation="vertical"
                  h="21px"
                  ml="44px"
                  display={{ base: "none", md: "initial" }}
                />
              )}
            </>
          )
        })}
      </HStack>
    </Flex>
  )
}

export default Categories
