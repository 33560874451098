import React from "react"
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Image,
  Stack,
} from "@chakra-ui/react"

const MoreQuestions = () => {
  return (
    <Box bg="brandGreen" w="full">
      <Container maxW={"container.xl"}>
        <Flex
          py={{ base: "16px", md: "252px" }}
          alignItems="center"
          flexDirection="column"
        >
          <Heading
            fontSize={{ base: "30px", md: "64px" }}
            letterSpacing="-0.03em"
            color="white"
            textAlign="center"

          >
            More questions? Need support?
          </Heading>
          <Text
            fontSize={{ base: "14px", md: "24px" }}
            color="white"
            fontWeight="regular"
            mt={{ base: "16px", md: "20px" }}
          >
            Get in touch with us
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={{ base: "10px", md: "78px" }}
            mt={{ base: "24px", md: "49px" }}
          >
            <Flex alignItems="center">
              <Image
                src="/images/mail-icon.svg"
                mr={{ base: "10px", md: "20px" }}
                w={{ base: "48px", md: "67px" }}
              />
              <Heading
                fontSize={{ base: "18px", md: "24px" }}
                letterSpacing="-0.03em"
                color="white"
              >
                hello@weld.dev
              </Heading>
            </Flex>
            <Flex alignItems="center">
              <Image
                src="/images/slack-icon.svg"
                mr={{ base: "10px", md: "20px" }}
                w={{ base: "48px", md: "67px" }}
              />
              <Heading
                fontSize={{ base: "18px", md: "24px" }}
                letterSpacing="-0.03em"
                color="white"
              >
                our Slack channel
              </Heading>
            </Flex>
          </Stack>
        </Flex>
      </Container>
    </Box>
  )
}

export default MoreQuestions
